import React from "react";

const Nav = () => {
  return (
    <header className="main-nav">
      <span className="logo">AdeDevs</span>
      <nav className="main-nav-list">
        <ul className="nav-item">
          <a href="#about">About</a>
        </ul>
        <ul className="nav-item">
          <a href="#stack">Stack</a>
        </ul>
        <ul className="nav-item">
          <a href="#projects">Projects</a>
        </ul>
        <ul className="nav-item">Resume</ul>
      </nav>
    </header>
  );
};

export default Nav;
