import React from "react";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="glass-card">
        <h2 className="about-title">About Me</h2>
        <p className="about-text">
          Born in Lagos, Nigeria, I am passionate about technology and enjoy
          learning, building, and writing about the tech stack I use. It pushes
          me to create seamless applications with optimal performance. When not
          coding or exploring new tech, I immerse myself in music and books. I
          enjoy discovering various genres and new artists. It serves as a
          creative outlet alongside coding.
        </p>
      </div>
    </section>
  );
};

export default About;
