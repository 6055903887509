import React, { useState } from "react";
import "./styles.css";
import Nav from "./components/Nav";
import HeroSection from "./components/HeroSection";
import About from "./components/About";
import MainContent from "./components/MainContent";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="app">
      <Nav />
      <HeroSection />
      <About />
      <MainContent />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
