import React from "react";
import { motion } from "framer-motion";

const HeroSection = () => {
  return (
    <section id="home" className="hero">
      <div className="hero-content">
        <motion.h1
          initial={{ opacity: 0, y: -120 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            bounceStiffness: 300,
            bounce: 0.7,
            duration: 0.9
          }}
          className="hero-title"
        >
          Hello, I'm Ade
        </motion.h1>
        <p className="hero-subtitle">
          Full-stack web developer with a passion for creating intuitive
          applications that offer seamless experiences and optimal performance.
          Let's create innovative Apps together!
          <span className="emoji">😃</span>
        </p>
        <a href="#contact" className="hero-button">
          Let's Chat!
        </a>
      </div>
    </section>
  );
};

export default HeroSection;

/*
import React from "react";

const HeroSection = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title">Hello, I'm Ade</h1>
        <p className="hero-subtitle">
          Full-stack web developer with a passion for creating intuitive
          applications and products that offer seamless experiences and optimal
          performance. Let's create innovative products together!
          <span className="emoji">😃</span>
        </p>
        <button className="hero-button">Let's Chat!</button>
      </div>
    </section>
  );
};

export default HeroSection;

*/
