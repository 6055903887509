import { useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Projects = () => {
  const projects = [
    {
      id: 1,
      image:
        "https://images.pexels.com/photos/5748630/pexels-photo-5748630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Dog DayCare App",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      link: "https://example.com/project1"
    },
    {
      id: 2,
      image:
        "https://images.pexels.com/photos/4087576/pexels-photo-4087576.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Todo App with Pomodoro",
      description:
        "Nulla venenatis semper mauris, id tristique dolor congue eu.",
      link: "https://example.com/project2"
    },
    {
      id: 3,
      image:
        "https://images.pexels.com/photos/5939401/pexels-photo-5939401.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Social App for Music and Books",
      description:
        "Nulla venenatis semper mauris, id tristique dolor congue eu.",
      link: "https://example.com/project2"
    },
    {
      id: 4,
      image:
        "https://images.pexels.com/photos/2599244/pexels-photo-2599244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Chat Application with GPT",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      link: "https://example.com/project1"
    }
  ];

  const cardVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 }
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.4 // Adjust the threshold value as needed
  });

  useEffect(() => {
    if (inView) {
      // Start the animation for each project card
      const projectCards = document.querySelectorAll(".project-card");
      projectCards.forEach((card, index) => {
        card.style.transitionDelay = `${index * 0.2}s`;
      });
    }
  }, [inView]);

  return (
    <section id="projects" className="projects">
      <h2 className="projects-header">Projects</h2>
      <hr />
      <div className="project-cards">
        {projects.map((project, index) => (
          <motion.div
            className="project-card"
            key={project.id}
            variants={cardVariants}
            ref={index === 0 ? ref : null} // Apply the ref to the first project card only
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            <motion.img
              src={project.image}
              alt={project.title}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
            />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              View Project
            </a>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Projects;

/*  const projects = [
    {
      id: 1,
      image:
        "https://images.pexels.com/photos/5748630/pexels-photo-5748630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Dog DayCare App",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      link: "https://example.com/project1"
    },
    {
      id: 2,
      image:
        "https://images.pexels.com/photos/4087576/pexels-photo-4087576.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Todo App with Pomodoro",
      description:
        "Nulla venenatis semper mauris, id tristique dolor congue eu.",
      link: "https://example.com/project2"
    },
    {
      id: 3,
      image:
        "https://images.pexels.com/photos/5939401/pexels-photo-5939401.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Social App for Music and Books",
      description:
        "Nulla venenatis semper mauris, id tristique dolor congue eu.",
      link: "https://example.com/project2"
    },
    {
      id: 4,
      image:
        "https://images.pexels.com/photos/2599244/pexels-photo-2599244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Chat Application with GPT",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      link: "https://example.com/project1"
    }
  ]; */
