import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-icons">
        <a href="#" className="footer-icon" title="Facebook">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="#" className="footer-icon" title="Twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="#" className="footer-icon" title="Instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
      <p className="footer-text">© 2023 AdeDevs. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
