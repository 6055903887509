import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faCodeBranch,
  faLaptopCode
} from "@fortawesome/free-solid-svg-icons";

const MainContent = () => {
  return (
    <div id="stack" className="container">
      <motion.div
        className="card"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.8 }}
      >
        <FontAwesomeIcon icon={faLaptopCode} className="card-icon" />
        <h3 className="card-title">Front-End</h3>
        <ul className="card-list">
          <li>Html/CSS/Javascript </li>
          <li>React/Next.Js</li>
          <li>Typescript</li>
        </ul>
      </motion.div>
      <motion.div
        className="card"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <FontAwesomeIcon icon={faCode} className="card-icon" />
        <h3 className="card-title">Back-End</h3>
        <ul className="card-list">
          <li>Node.Js</li>
          <li>MongoDB</li>
          <li>PostgreSQL</li>
        </ul>
      </motion.div>
      <motion.div
        className="card"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <FontAwesomeIcon icon={faCodeBranch} className="card-icon" />
        <h3 className="card-title">Tools</h3>
        <ul className="card-list">
          <li>Git</li>
          <li>Docker</li>
          <li>Webpack/Postman</li>
        </ul>
      </motion.div>
    </div>
  );
};

export default MainContent;
